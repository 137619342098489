import React, { useEffect, useState, createRef, useRef, useCallback } from 'react';
import { Header, PropertyListTopBar, NewPropertyCard, NewPropertyMap, NewFiltersModal, EmergingAreaModal, Pagination, MobilePropertyCardOverlay, SignUpModal, NoAvailableProperties, FreeTrialExpired, LoadingStatic, OffMarketCard, OffMarketModal, MobileOffMarketCardOverlay, PropertyListButton, DataUploadingScreen, TextLoading, StrategyLoading, FSBOOnly, OversizedQuery } from '../components';
import { checkOnAuthStateChanged, getDocument, getMiddle, isOdd, saveURLZipCodes, downloadExcel, sortValues, saveURLTracts, checkUserId, setData, getAPIGatewayListings, recordEvent, saveSearchParams, getUserDocuments, saveNewFavourites, cloudFunctionV2, downloadExcelFreeTrial } from '../functions';
import { ChevronUp, Sort } from '../assets';
import '../styles/NewPropertyList.css';
import { isBrowser, isMobile } from 'react-device-detect';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { colour, SortSelect, formatterCompact, formatterLong, defaultUserData, metrosForPurchase, defaultFilters, numberFormatter, neighbourhoodDataOptions } from '../styles/GlobalStyles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

function NewPropertyList() {
	const params = useParams();
	const cityId = params.id;
	const [userDetails, setUserDetails] = useState(null);
	const [cityStateName, setCityStateName] = useState("");
	const [buyBoxes, setBuyBoxes] = useState([]);
	const [properties, setProperties] = useState([]);
	const [mapProperties, setMapProperties] = useState([]);
	const [allProperties, setAllProperties] = useState([]);
	const [centreCoordinates, setCentreCoordinates] = useState({lat: 0, lng: 0});
	const [filtersModalOpen, setFiltersModalOpen] = useState(false);
	const [zipCodeList, setZipCodeList] = useState([]);
	const [viewedProperties, setViewedProperties] = useState([]);
	const [propertiesOnPage, setPropertiesOnPage] = useState(0);
	const [propertiesOnPageChange, setPropertiesOnPageChange] = useState(false);
	const [overlay, setOverlay] = useState(true);
	const [mobileAbstract, setMobileAbtract] = useState("");
	const [noProperties, setNoProperties] = useState(false);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const [hoverCard, setHoverCard] = useState("");
	const [propertyRefs, setPropertyRefs] = useState([]);
	const [onboarding, setOnboarding] = useState(true);
	const [onboardingStep, setOnboardingStep] = useState(0);
	const [cityName, setCityName] = useState("");
	const [disabled, setDisabled] = useState(false);
	const [preFilteredProperties, setPreFilteredProperties] = useState([]);
	const cardsRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [freeTrial, setFreeTrial] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [cardIndex, setCardIndex] = useState(null);
	const [cardClick, setCardClick] = useState(false);
	const [justLoaded, setJustLoaded] = useState(false);
	const [totalProperties, setTotalProperties] = useState(0);
	const [selectedMapType, setSelectedMapType] = useState(0);
	const [mapViewOpen, setMapViewOpen] = useState(false);
	const [selectedTracts, setSelectedTracts] = useState([]);
	const [lastClickedZIPCode, setLastClickedZIPCode] = useState("");
	const [lastClickedTract, setLastClickedTract] = useState("");
	const getSearchQueryState = searchParams.get('searchQueryState');
	const getPage = searchParams.get('page');
	const getOffMarket = searchParams.get('offMarket');
	const getDownPayment = searchParams.get('downPayment');
	const [page, setPage] = useState(getPage === null ? 1 : Number(getPage));
	const getSorting = searchParams.get('sort');
	const [sorting, setSorting] = useState(getSorting === null ? 0 : Number(getSorting));
	const [showResetButton, setShowResetButton] = useState(getSearchQueryState === null ? false : true);
	const [mapSidePanelOpen, setMapSidePanelOpen] = useState(null);
	const [freeTrialExpired, setFreeTrialExpired] = useState(false);
	const [temporaryId, setTemporaryId] = useState(false);
	const [signUpModal, setSignUpModal] = useState(false);
	const [sidePanelCloseClicked, setSidePanelCloseClicked] = useState(false);
	const [offMarket, setOffMarket] = useState(getOffMarket === null ? false : getOffMarket === "true" ? true : false);
	const [onOffMarketChange, setOnOffMarketChange] = useState(false);
	const [offMarketModal, setOffMarketModal] = useState(false);
	const [changeMade, setChangeMade] = useState("");
	const [addressSearchedProperty, setAddressSearchedProperty] = useState(null);
	const [googleMapProperty, setGoogleMapProperty] = useState(null);
	const [userSubscriptions, setUserSubscriptions] = useState([]);
	const [showStrategyTooltip, setShowStrategyTooltip] = useState(true);
	const getPropertyTypes = searchParams.get('propertyType');
	const parsedGetPropertyTypes = getPropertyTypes !== null ? JSON.parse(getPropertyTypes) : null;
	const [propertyTypes, setPropertyTypes] = useState([
		{
            code: "MultiFamily",
            included: getPropertyTypes !== null && parsedGetPropertyTypes.propertyTypes.includes("MFH") ? true : false,
            property: "Multi-Family",
            abbreviation: "MFH"
        },
        {
            code: "SingleFamily",
            included: getPropertyTypes !== null && parsedGetPropertyTypes.propertyTypes.includes("SFH") ? true : false,
            property: "Single-Family",
            abbreviation: "SFH"
        }
	]);
	const [units, setUnits] = useState(parsedGetPropertyTypes !== null && parsedGetPropertyTypes.units !== undefined ? parsedGetPropertyTypes.units : 1);
	const getBudgetRange = searchParams.get('budgetRange');
    const [budgetRange, setBudgetRange] = useState(getBudgetRange !== null ? JSON.parse(getBudgetRange) : [0, 1000000000]);
	const getPropertySpecifics = searchParams.get('propertySpecifics');
	const parsedPropertySpecifics = getPropertySpecifics !== null ? JSON.parse(getPropertySpecifics) : null;
    const [bedrooms, setBedrooms] = useState(parsedPropertySpecifics !== null ? parsedPropertySpecifics.bedrooms : 1);
	const [bathrooms, setBathrooms] = useState(parsedPropertySpecifics !== null ? parsedPropertySpecifics.bathrooms : 1);
	const [squareFootage, setSquareFootage] = useState(parsedPropertySpecifics !== null ? parsedPropertySpecifics.squareFootage : [0, 999999]);
	const [constructionYears, setConstructionYears] = useState(parsedPropertySpecifics !== null && parsedPropertySpecifics.constructionYears !== undefined ? parsedPropertySpecifics.constructionYears : ["", ""]);
	const getValueAdd = searchParams.get('valueAdd');
	const parsedValueAdd = getValueAdd !== null ? JSON.parse(getValueAdd) : null;
    const [onlyADU, setOnlyADU] = useState(parsedValueAdd !== null ? parsedValueAdd.onlyADU : false);
    const [onlyExtraBedroom, setOnlyExtraBedroom] = useState(parsedValueAdd !== null ? parsedValueAdd.onlyExtraBedroom : false);
	const [hideFixerUppers, setHideFixerUppers] = useState(parsedValueAdd !== null ? parsedValueAdd.hideFixerUppers : false);
	const [onlyFixerUppers, setOnlyFixerUppers] = useState(parsedValueAdd !== null ? parsedValueAdd.onlyFixerUppers : false);
	const getMoreFilters = searchParams.get('more');
	const parsedMoreFilters = getMoreFilters !== null ? JSON.parse(getMoreFilters) : null;
    const [priceReduction, setPriceReduction] = useState(parsedMoreFilters !== null ? parsedMoreFilters.priceReduction : false)
	const [priceReductionIndex, setPriceReductionIndex] = useState(parsedMoreFilters !== null && parsedMoreFilters.priceReduction === true ? parsedMoreFilters.priceReductionIndex : 0);
    const [onlySellerFinancing, setOnlySellerFinancing] = useState(parsedMoreFilters !== null ? parsedMoreFilters.onlySellerFinancing : false);
	const [favourites, setFavourites] = useState([]);
	const [financialPreferences, setFinancialPreferences] = useState(null);
	const [cityNoAccess, setCityNoAccess] = useState(false);
	const [dataUploading, setDataUploading] = useState(false);
	const [strategiesAvailable, setStrategiesAvailable] = useState(false);
	const getStrategyParam = searchParams.get('strategy');
	const [strategy, setStrategy] = useState(getStrategyParam === null ? 0 : Number(JSON.parse(getStrategyParam)));
	const [stateName, setStateName] = useState("");
	const [emergingMarkets, setEmergingMarkets] = useState([]);
	const [emergingAreaModal, setEmergingAreaModal] = useState(false);
	const [emergingAreaTract, setEmergingAreaTract] = useState(null);
	const [activeMarket, setActiveMarket] = useState(null);
	const [creativeSubscription, setCreativeSubscription] = useState(false);

	const [neighbourhoodData, setNeighbourhoodData] = useState([]);
	const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
	const [neighbourhoodOption, setNeighbourhoodOption] = useState(0);
	const [tracts, setTracts] = useState([]);
	const [clickedTract, setClickedTract] = useState("");
	const [chosenTract, setChosenTract] = useState(null);
	const [hoverMapLegend, setHoverMapLegend] = useState(null);
	const [loadingStage, setLoadingStage] = useState(0);
	const [firstLoading, setFirstLoading] = useState(true);
	const [strategiesLoading, setStrategiesLoading] = useState(true);
	const metroCityId = metrosForPurchase.findIndex(e => e.city_id === cityId);
	const upgradeMetro = freeTrial === true && metroCityId !== -1 ? metrosForPurchase[metroCityId] : null;
	const strategyRefs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null)
	];
	const [strategyAnimations, setStrategyAnimations] = useState([
		true,
		false,
		false,
		false,
		false,
		false
	]);
	const [fixerUpperOption, setFixerUpperOption] = useState(onlyFixerUppers === true ? "fu" : hideFixerUppers === true ? "tk" : "both");
	const [fsbo, setFsbo] = useState(parsedMoreFilters !== null ? parsedMoreFilters.fsbo : 0);
	const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(parsedMoreFilters !== null ? parsedMoreFilters.neighborhoodGradeMin : 1);
	const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(parsedMoreFilters !== null ? parsedMoreFilters.neighborhoodGradeMax : 8);
	const [oversizedQuery, setOversizedQuery] = useState(false);
	const [hideHeatmap, setHideHeatmap] = useState(false);
	const chosenStrategyRef = useRef(null);
	const hostname = window.location.hostname;
	const liveSite = hostname === "localhost" || window.location.hostname.includes("192.") || hostname.includes("refi") ? false : true;
	const sortOptions = [
		{
			label: "Sort"
		},
		{
			label: "Strategies"
		},
		{
			label: "Newest to oldest"
		},
		{
			label: "Oldest to newest"
		},
		{
			label: "Cash on cash return"
		},
		{
			label: "Price - lowest to highest"
		},
		{
			label: "Price - highest to lowest"
		},
		{
			label: "ARV Spread - highest to lowest"
		}
	];
	const navigate = useNavigate();

	const escFunction = useCallback((event) => {
		if ( event.key === "Escape" && onboarding === false ) {
			setOnboarding(true);
		}
	}, [onboarding]);

	useEffect(() => {
		const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);

		async function checkLoggedIn() {
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const userId = user.userId;
				const collections = [
					"Users",
					"Buy Boxes",
					"Subscriptions",
					"Financial Preferences",
					"Favourites"
				];

				const queryUserData = await getUserDocuments(collections, userId);
				if ( queryUserData[0].status === 200 ) {
					const userData = queryUserData[0].data;
					setUserDetails(userData);

					if ( queryUserData[1].status === 200 ) {
						setBuyBoxes(queryUserData[1].data);

						const queriedBuyBoxes = queryUserData[1].data;
						const buyBoxIndex = queriedBuyBoxes.findIndex(e => e.cityId === cityId);
						if ( buyBoxIndex !== -1 && queriedBuyBoxes[buyBoxIndex].loaded === false ) {
							setDataUploading(true);
						}
					}
					
					const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        			const isAdmin = adminIds.includes(user.userId);
					if ( userData.freeTrial === undefined || userData.freeTrial.length === 0 ) {
						navigate('/pick-city');
					}
					else if ( isAdmin === true ) {
						setFreeTrial(false);
						setCreativeSubscription(true);
						const checkViewedProperties = userData.viewedProperties;
						if ( checkViewedProperties !== undefined ) {
							setViewedProperties(userData.viewedProperties);
						}

						if ( queryUserData[3].status === 200 ) {
							const settings = queryUserData[3].data;
							queryGatewayProperties(userData, settings);
						}
						else {
							const settings = defaultUserData.settings;
							queryGatewayProperties(userData, settings);
						}
						getNeighbourhoodData();
					}
					else if ( queryUserData[2].status === 200 ) {
						const subscriptions = queryUserData[2].data;
						const today = new Date();
						const todaySeconds = today.getTime() / 1000;
						const activeSubscription = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
						const checkCreativeSub = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds && e.creative === true);

						const freeTrialCheck = userData.freeTrial;
						const freeTrialeEndDate = freeTrialCheck[0].endDate.seconds;

						setUserSubscriptions(subscriptions);
			
						if ( activeSubscription !== -1 ) {

							if ( checkCreativeSub !== -1 ) {
								setCreativeSubscription(true);
							}

							setFreeTrial(false);
							const checkViewedProperties = userData.viewedProperties;
							if ( checkViewedProperties !== undefined ) {
								setViewedProperties(userData.viewedProperties);
							}

							if ( queryUserData[3].status === 200 ) {
								const settings = queryUserData[3].data;
								queryGatewayProperties(userData, settings);
							}
							else {
								const settings = defaultUserData.settings;
								queryGatewayProperties(userData, settings);
							}
							getNeighbourhoodData();
						}
						else if ( freeTrialeEndDate > todaySeconds ) {
							setFreeTrial(true);
							setCreativeSubscription(true);
							const checkViewedProperties = userData.viewedProperties;
							if ( checkViewedProperties !== undefined ) {
								setViewedProperties(userData.viewedProperties);
							}

							if ( queryUserData[3].status === 200 ) {
								const settings = queryUserData[3].data;
								queryGatewayProperties(userData, settings);
							}
							else {
								const settings = defaultUserData.settings;
								queryGatewayProperties(userData, settings);
							}
							getNeighbourhoodData();
						}
						else {
							setLoading(false);
							setCityNoAccess(true);
							setFreeTrialExpired(true);
						}
					}
					else {
						const freeTrialCheck = userData.freeTrial;
						const endDate = freeTrialCheck[0].endDate.seconds;
						const today = new Date();
						const todaySeconds = today.getTime() / 1000;
			
						if ( endDate > todaySeconds ) {
							const checkViewedProperties = userData.viewedProperties;
							if ( checkViewedProperties !== undefined ) {
								setViewedProperties(userData.viewedProperties);
							}

							if ( queryUserData[3].status === 200 ) {
								const settings = queryUserData[3].data;
								queryGatewayProperties(userData, settings);
							}
							else {
								const settings = defaultUserData.settings;
								queryGatewayProperties(userData, settings);
							}
							getNeighbourhoodData();
						}
						else if ( endDate > todaySeconds ) {
							setLoading(false);
							setCityNoAccess(true);
							setFreeTrialExpired(true);
						}
						else {
							setFreeTrialExpired(true);
							setCityNoAccess(false);
							setLoading(false);
						}
					}

					if ( queryUserData[4].status === 200 ) {
						setFavourites(queryUserData[4].data);
					}
					else {
						setFavourites(userData.favourites);
						saveNewFavourites(userData.favourites, userId);
					}
				}
				else {
					navigate("/sign-up");
				}
			}
			else if ( searchParams.get('id') !== null ) {
				const getTemporaryId = searchParams.get('id');
				const temporaryIdColRef = "Unique URLs";
        		const temporaryIdDocRef = "URLs";
				const queryTemporaryUsers = await getDocument(temporaryIdColRef, temporaryIdDocRef);
				if ( queryTemporaryUsers.status === 200 ) {
					const temporaryIds = queryTemporaryUsers.data.data;
					const temporaryIdIndex = temporaryIds.findIndex(e => e.id === getTemporaryId);
					if ( temporaryIdIndex !== -1 ) {
						const rightNow = new Date();
						const checkMilliseconds = rightNow.getTime();
						const endTime = temporaryIds[temporaryIdIndex].endDate;
						if ( checkMilliseconds > endTime ) {
							navigate("/link-expired");
						}
						else if ( temporaryIds[temporaryIdIndex].city !== cityId ) {
							navigate("/sign-up");
						}
						else {
							setTemporaryId(true);
							setOverlay(false);

							recordEvent("Temporary URL Session", {
								cityId: cityId,
								city: ""
							});
							queryGatewayProperties(defaultUserData, defaultUserData.settings);
							getNeighbourhoodData();
						}
					}
					else {
						navigate('/sign-up', {
							state: {
								cityId: cityId
							}
						});
					}
				}
				else {
					navigate('/sign-up', {
						state: {
							cityId: cityId
						}
					});
				}
			}
			else {
				document.title = "Property Listings";
				navigate('/sign-up', {
					state: {
						cityId: cityId
					}
				});
			}
		}

		async function queryGatewayProperties(userData, settings) {
			setLoadingStage(1);
			setFinancialPreferences(settings);
			const newFilters = {
				adu: onlyADU,
				bathrooms: bathrooms,
				bedrooms: bedrooms,
				budgetRange: budgetRange,
				constructionYears: constructionYears,
				squareFootage: squareFootage,
				units: units,
				propTypeMislabelled: false,
				sellerFinancing: onlySellerFinancing,
				priceReduction: priceReduction,
				extraBedroom: onlyExtraBedroom,
				propertyTypes: propertyTypes,
				hideFixerUppers: hideFixerUppers,
				onlyFixerUppers: onlyFixerUppers,
				priceReductionIndex: priceReductionIndex,
				neighbourhoodGradeMin: neighbourhoodGradeMin,
				neighbourhoodGradeMax: neighbourhoodGradeMax,
				fsbo: fsbo
			};
			const getGatewayQueryString = await getAPIGatewayListings(settings, offMarket, cityId, newFilters);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "listings",
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
			let queryData = [];
			if ( getGateway.status === 200 ) {
				const getGatewayBody = getGateway.body;
				setActiveMarket(getGatewayBody.activeRegionFlag);

				if ( neighbourhoodGradeMax === 8 && neighbourhoodGradeMin === 1 ) {
					queryData = getGatewayBody.props;
				}
				else {
					const removedNeighbourhoodGrade = [];
					for (let index = 0; index < getGatewayBody.props.length; index++) {
						const element = getGatewayBody.props[index];
						if ( element.neighborhoodGrade !== null ) {
							removedNeighbourhoodGrade.push(element);
						}
					};
					queryData = removedNeighbourhoodGrade;
				}

				setTotalProperties(getGatewayBody.totalPropertyCount);
				if ( liveSite === false ) {
					console.log("Gateway query");
				}
			}
			else if ( getGateway.status === 404 ) {
				const customS3Location = getGateway.body.s3_location;
				if ( liveSite === false ) {
					console.log("Gateway 404 -> S3");
				}
				const params = {
					type: "s3",
					s3Data: {
						key: customS3Location,
						bucketName: "residentialpropertydata"
					}
				};
				const getS3Query = await cloudFunctionV2(gatewayURL, params);
				if ( getS3Query.status === 200 ) {
					const getS3Body = getS3Query.body;
					setTotalProperties(getS3Body.totalPropertyCount);
					setActiveMarket(getS3Body.activeRegionFlag);

					if ( neighbourhoodGradeMax === 8 && neighbourhoodGradeMin === 1 ) {
						queryData = getS3Body.props;
					}
					else {
						const removedNeighbourhoodGrade = [];
						for (let index = 0; index < getS3Body.props.length; index++) {
							const element = getS3Body.props[index];
							if ( element.neighborhoodGrade !== null ) {
								removedNeighbourhoodGrade.push(element);
							}
						};
						queryData = removedNeighbourhoodGrade;
					}
				}
				else if ( getS3Query.status === 406 ) {
					setOversizedQuery(true);
					setLoading(false);
					setStrategiesLoading(false);
					setFirstLoading(false);
				}
			}
			else if ( getGateway.status === 406 ) {
				setOversizedQuery(true);
				setLoading(false);
				setStrategiesLoading(false);
				setFirstLoading(false);
			}
			else {
				setNoProperties(true);
				setLoading(false);
				setStrategiesLoading(false);
				setFirstLoading(false);
			}

			if ( queryData.length !== 0 ) {
				getNeighbourhoodData();
				setPreFilteredProperties(queryData);
				const propertyState = queryData[0].address.state;
				setStateName(propertyState);

				const newMapArray = [];
				for (let index = 0; index < queryData.length; index++) {
					const element = queryData[index];
					queryData[index].financials.renovation = 0;
					queryData[index].purchaseCosts = {
						closingCostRate: 0.03
					};
					
					if ( queryData[index].stackedList !== undefined ) {
						queryData[index].offMarket = true;

						const motivatedSellerArray = [
							queryData[index].motivatedSeller.absentee_flag === 0 ? false : true,
							queryData[index].motivatedSeller.auction_flag === 0 ? false : true,
							queryData[index].motivatedSeller.death_flag === 0 ? false : true,
							queryData[index].motivatedSeller.free_clear_flag === 0 ? false : true,
							queryData[index].motivatedSeller.high_equity_flag === 0 ? false : true,
							queryData[index].motivatedSeller.in_state_absentee_owner_flag === 0 ? false : true,
							queryData[index].motivatedSeller.inherited_flag === 0 ? false : true,
							queryData[index].motivatedSeller.lien_flag === 0 ? false : true,
							queryData[index].motivatedSeller.multiple_mortgages_flag === 0 ? true : false,
							queryData[index].motivatedSeller.owner_occupied_flag === 0 ? true : false,						
							queryData[index].motivatedSeller.pre_foreclosure_flag === 0 ? false : true,
							queryData[index].motivatedSeller.spousal_death_flag === 0 ? false : true,
							queryData[index].motivatedSeller.tax_lien_flag === 0 ? false : true,
							queryData[index].motivatedSeller.vacant_flag === 0 ? false : true
						];
						
						const emptyArray = [];
						const totalTrueArray = [];
						let motivatedSellerFourthIndex = 99;
						for (let i = 0; i < motivatedSellerArray.length; i++) {
							const subElement = motivatedSellerArray[i];
							if ( subElement === true && emptyArray.length < 4 ) {
								emptyArray.push(subElement);
							}

							if ( subElement === true ) {
								totalTrueArray.push(true);
							}

							if ( emptyArray.length === 4 && motivatedSellerFourthIndex === 99 ) {
								motivatedSellerFourthIndex = i;
							}
						}
						queryData[index].motivatedSellerCount = totalTrueArray.length;
						queryData[index].motivatedSellerFourthIndex = motivatedSellerFourthIndex;
					}
					else {
						queryData[index].offMarket = false;
					}

					if ( element.financials.cashOnCash !== null && element.latitude !== null && element.longitude !== null ) {
						const newMapObject = {
							financials: element.financials,
							longitude: element.longitude,
							latitude: element.latitude,
							address: element.address,
							zpid: element.zpid,
							topPick: element.topPick
						}
						newMapArray.push(newMapObject);
					}

					if ( element.arvComps === true && element.fixerUpper === true && element.propertyTypeDimension === "Single Family" ) {
						const arvPrice = element.arvPrice;
						const purchasePrice = element.price;
						const arvSpread = (arvPrice - purchasePrice) / purchasePrice;
						element.arvSpread = arvSpread;
					}
					else {
						element.arvSpread = -1;
					}
				}

				if ( queryData.length !== 0 ) {
					setNoProperties(false);
					saveCityName(queryData);
					const props = await sortValues(queryData, sorting, strategy);
					const lowerBound = (page - 1) * 20;
					const upperBound = 20 * page;
					const propsSegmentedByPage = props.slice(lowerBound, upperBound);
					let mapLowerBound;

					if ( isOdd(page) === 1 ) {
						mapLowerBound = (page - 1) * 20;
					}
					else {
						mapLowerBound = (page - 2) * 20;
					}
					const mapUpperBound = mapLowerBound + 40;
					const propsSegmentedByMapPage = props.slice(mapLowerBound, mapUpperBound);

					if ( propsSegmentedByPage.length !== 0 ) {
						if ( propsSegmentedByPage[0].dripScoreOptions === undefined ) {
							setStrategiesAvailable(false);
						}
						else {
							setStrategiesAvailable(true);
						}
					}

					parseFloat(findCentre(propsSegmentedByPage)[0]);
					parseFloat(findCentre(propsSegmentedByPage)[1]);
					setProperties(propsSegmentedByPage);

					const imageArrayLength = [];
					for (let index = 0; index < propsSegmentedByPage.length; index++) {
						const element = propsSegmentedByPage[index];
						const propImages = element.images;
						if ( propImages !== null ) {
							imageArrayLength.push(propImages.length);
						}
						else {
							imageArrayLength.push(0);
						}
					};

					setMapProperties(propsSegmentedByMapPage);
					setAllProperties(props);
					setPropertiesOnPage(props.length < 21 ? props.length : 20);
					setLoading(false);
					setFirstLoading(false);
					setStrategiesLoading(false);
					if ( onOffMarketChange === true ) {
						setOnOffMarketChange(false);
						setPropertiesOnPageChange(true);
					}

					if ( userData.offMarketModal !== true && offMarket === true ) {
						setOffMarketModal(true);
					}

					if ( changeMade === "filters" ) {
						setChangeMade("loaded");
						setPropertiesOnPageChange(true);
					}

					const propertyLength = propsSegmentedByPage.length;
					setPropertyRefs((elRefs) =>
						Array(propertyLength)
						.fill()
						.map((_, i) => elRefs[i] || createRef()),
					);

					saveZIPList(props);
					setOnboarding(true);
				}
				else {
					parseFloat(findCentre(queryData)[0]);
					parseFloat(findCentre(queryData)[1]);
					setNoProperties(true);
					setLoading(false);
				}
			}
			else {
				const newGetGatewayQueryString = await getAPIGatewayListings(settings, offMarket, cityId, defaultFilters);
				const newGatewayData = {
					type: "gateway",
					resourceId: "listings",
					queryString: newGetGatewayQueryString
				};
				const consolationGetGateway = await cloudFunctionV2(gatewayURL, newGatewayData);
				if ( consolationGetGateway.status === 200 ) {
					const consolationProps = consolationGetGateway.body.props;
					parseFloat(findCentre(consolationProps)[0]);
					parseFloat(findCentre(consolationProps)[1]);
				}
				
				setNoProperties(true);
				setLoading(false);
				setFirstLoading(false);
				setStrategiesLoading(false);
			}
		};

		async function findCentre(markers) {
			const latMiddle = getMiddle('latitude', markers);
			const lngMiddle = getMiddle('longitude', markers);
			const centreObject = {
				lat: latMiddle,
				lng: lngMiddle
			};
			setCentreCoordinates(centreObject);
		}

		const saveCityName = async(newProps) => {
			if ( newProps.length !== 0 ) {
				const firstProp = newProps[0];
				const city = firstProp.address.city;
				const state = firstProp.address.state;
				const cityState = `${city}, ${state}`;
				setCityName(city);
				setCityStateName(cityState);
				document.title = `Coffee Clozers - ${cityState}`;
			}
		};

		const getNeighbourhoodData = async() => {
			const neighbourhoodPath = cityId.includes("CTY") ? `geo_output/map_neighborhood/county/${cityId.replace("CTY", "")}.json` : `output/map_neighborhood/${cityId}.json`
			const params = {
				s3Data: {
					key: neighbourhoodPath,
					bucketName: "residentialpropertydata"
				},
				type: "s3"
			}
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, params);
			setLoadingStage(2);
			if ( queryData.status === 200 ) {
				const body = queryData.body;
				setNeighbourhoodData(body);

				const newBounds = [];
				const getTracts = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					getTracts.push(element.region.tractId);
					const tractPolygon = element.tractPolygon.flat(1);
					newBounds.push(tractPolygon);
				};
				setTracts(getTracts);
				setNeighbourhoodBounds(newBounds);

				const emergingMarketArray = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					if ( element.region.emergingMarket === true ) {
						emergingMarketArray.push(element);
					}
				};
				setEmergingMarkets(emergingMarketArray);
			}
		}

		async function saveZIPList(allProps) {
			if ( getSearchQueryState !== null ) {
				setJustLoaded(true);
				const parsedSearch = JSON.parse(getSearchQueryState);
				const urlCityId = parsedSearch.cityId;
				const savedTracts = parsedSearch.tracts;

				if ( cityId === urlCityId && savedTracts.length !== 0 ) {
					saveURLTracts(savedTracts, setSelectedTracts, allProps, setPage, page, setLoading, setMapProperties, sorting, setProperties, setPropertiesOnPageChange, strategy);
					setSelectedMapType(0);
				}
				else if ( cityId === urlCityId && savedTracts.length === 0 ) {
					saveURLZipCodes(
						getSearchQueryState, 
						setLoading,
						setMapProperties,
						page,
						sorting,
						setShowResetButton,
						allProps,
						setProperties,
						strategy
					);
					setSelectedMapType(1);
				}
				else {
					setSearchParams({});
				}
			}
		}

		if (typeof structuredClone === 'undefined') {
			console.log("Structured clone undefined");
			alert("Your browser needs updating. Use Safari 15.4 or above, Firefox 94 or above, or Chrome 98 or above.");
		}
		else if ( changeMade !== "" && changeMade !== "onOffMarket" && changeMade !== "filters" ) {
			if ( liveSite === false ) {
				console.log("script exited = ", changeMade);
			}
		}
		else {
			if ( liveSite === false ) {
				console.log("script running");
				console.log("RUNNING -  properties on page change = ", propertiesOnPageChange);
			}

			if ( changeMade !== "filters" ) {
				// If filters were changed, we want
				// to wait to recalculate how many pages
				// there now are until the new properties
				// have been loaded.

				// We'll wait till new properties load, and
				// IF the properties loaded due to a filter change
				// THEN we will set propertiesOnPageChange to true
				// and THEN we will set ChangeMade to "loaded"

				setChangeMade("loaded");
			}
			setLoading(true);
			setOversizedQuery(false);
			checkLoggedIn();
		}

		document.addEventListener("keydown", escFunction, false);
		return () => {
		  document.removeEventListener("keydown", escFunction, false);
		  window.removeEventListener('resize', handleWindowResize);
		};
	}, [
		cityId, 
		navigate, 
		justLoaded, 
		searchParams, 
		setSearchParams, 
		escFunction, 
		page, 
		propertiesOnPageChange, 
		sorting, 
		getSearchQueryState, 
		sidePanelCloseClicked, 
		offMarket, 
		onOffMarketChange, 
		liveSite, 
		getDownPayment, 
		changeMade, 
		bathrooms, 
		bedrooms, 
		budgetRange, 
		constructionYears, 
		squareFootage, 
		units, 
		onlyADU, 
		onlyExtraBedroom, 
		propertyTypes, 
		hideFixerUppers, 
		onlyFixerUppers, 
		priceReduction, 
		priceReductionIndex, 
		onlySellerFinancing, 
		strategy,
		fsbo,
		neighbourhoodGradeMin,
		neighbourhoodGradeMax
	]);

	const loadingStages = [
        "1. Getting MLS data",
        "2. Generating neighborhood grade map",
        "3. Calculating cash-flow"
    ];

	const downloadExcelFile = () => {
		if ( preFilteredProperties.length !== 0 ) {
			const downPayment = financialPreferences.initialCosts[0].value === "" ? 20 : financialPreferences.initialCosts[0].value;
			const sheetStrategy = strategy === 0 || strategy === 5 ? 0 : strategy - 1;
			downloadExcel(preFilteredProperties, cityStateName, cityId, downPayment, false, sheetStrategy);
			recordEvent("Download CSV", {
				city: cityId,
				cityTitle: cityStateName,
				all: true,
				zpid: ""
			});
		}
	}

	const downloadExcelFileFreeTrial = () => {
		if ( preFilteredProperties.length !== 0 ) {
			const downPayment = financialPreferences.initialCosts[0].value === "" ? 20 : financialPreferences.initialCosts[0].value;
			const sheetStrategy = strategy === 0 || strategy === 5 ? 0 : strategy - 1;
			const first10Props = preFilteredProperties.slice(0, 10);
			downloadExcelFreeTrial(first10Props, cityStateName, cityId, downPayment, false, sheetStrategy);
			recordEvent("Download CSV Free Trial", {
				city: cityId,
				cityTitle: cityStateName,
				all: true,
				zpid: ""
			});
		}
	}

	const changeSorting = async(val) => {
		if ( temporaryId === false ) {
			setChangeMade("sorting");
			setLoading(true);
			const labels = [
				"sort",
				"page"
			];
			const values = [
				val,
				1
			];
			await saveSearchParams(labels, values, searchParams, setSearchParams);
			const checkedProperties = getSearchQueryState === null ? allProperties : mapProperties;

			const newProps = await sortValues(checkedProperties, val, strategy);
			const lowerBound = 0;
			const upperBound = 20;
			const propsSegmentedByPage = newProps.slice(lowerBound, upperBound);
			setProperties(propsSegmentedByPage);

			if ( getSearchQueryState === null ) {
				let mapLowerBound = 0;
				const mapUpperBound = 40;
				const propsSegmentedByMapPage = newProps.slice(mapLowerBound, mapUpperBound);
				setMapProperties(propsSegmentedByMapPage);
			}
			setPage(1);
			setLoading(false);
			setSorting(val);
			setPropertiesOnPageChange(true);
		}
		else {
			setSignUpModal(true);
		}
	}

	const sidePanelToggle = async() => {
		recordEvent("Side Panel Toggle", {
			open: !mapSidePanelOpen
		});

		setMapSidePanelOpen(!mapSidePanelOpen);
		if ( userDetails === null ) {
			return;
		}
		else if ( userDetails.sidePanel === true ) {
			return;
		}
		else {
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const colRef = "Users";
				const docRef = user.userId;
				userDetails.sidePanel = true;

				await setData(colRef, docRef, userDetails);
			}
		}
	};

	const clickOutside = (e) => {
		setCardIndex(null);
		setAddressSearchedProperty(null);
		setGoogleMapProperty(null);
	};

	const openOverlay = () => {
		setOverlay(!overlay);
		recordEvent("Overlay Toggle", {
			overlay: !overlay
		});

		if ( overlay === true ) {
			setTimeout(() => {
				if ( cardsRef.current !== null ) {
					cardsRef.current.scroll({
						top: 0,
						behavior: 'smooth'
					});
				}
			}, 250);
		}
	};

	const changeMapType = (index) => {
        if ( index !== 2 ) {
            recordEvent("Change Map Type", {
                mapType: index === 1 ? "ZIP Codes" : "Gentrification Heatmap"
            });
            setSelectedMapType(index);
        }
    };

	const resetFilters = async() => {
        setSearchParams({});
		window.location.reload();
	};

	const strategies = [
        {
            label: "Overall",
			title: "Overall",
			description: "This strategy provides a comprehensive view of all available properties. You'll see our favorite deals at the top 😊",
			shortDescription: "The best overall rental properties.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#c1bc39c67c9e49c9939651f0904929f2"
        },
		{
            label: "Turnkey (Buy & hold)",
			title: "Buy-and-Hold Rentals",
			description: "Re-calculate properties for traditional buy and hold investing. This is a long-term investment strategy of purchasing properties to hold and rent them for steady cash flow and potential appreciation.",
			shortDescription: "Turnkey properties in appreciating neighborhoods.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#9328a712c81b4f01900bc6168f702b00"
        },
		{
            label: "House Hack",
			title: "Househacking",
			description: "Re-calculate properties for house-hacking. Househacking means buying an investment property with the intention of living in one of the units or rooms (for at least one year) and renting out the others to cover expenses and generate income. This strategy allows the investor to make a much smaller down payment. We calculate this with a 5% down payment.",
			shortDescription: "Properties you can live in and rent out.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#7b629a671d234f23ab66f6c4a32887ac"
        },
		{
            label: "BRRRR",
			title: "BRRRR (Buy, Rehab, Rent, Refinance, Repeat)",
			description: "Re-calculate properties for BRRRR method. BRRRR is a real estate strategy involving buying undervalued properties, renovating for added value, renting out for income, refinancing to recover capital, and repeating the cycle.",
			shortDescription: "Deals you can rehab and later rent out",
			disabledSubscription: `Since ${stateName} is a non-disclosure state, we cannot calculate the ARV of properties in this city, which in turn means we cannot rank them for their BRRRR potential.`,
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#7309b252bfd44db087c07807b8cf15ce",
			disabledLink: "https://bezit.co/resources/non-disclosure-states-us/"
        },
		{
            label: "Fix & Flip",
			title: "Fix & Flip",
			description: "Re-calculate properties for househack strategy. Fix & flip is the short-term strategy of buying distressed properties, renovating them, and selling at a higher price to make a profit.",
			shortDescription: "Properties with great ARVs that you can renovate and sell profitably.",
			disabledSubscription: `Since ${stateName} is a non-disclosure state, we cannot calculate the ARV of properties in this city, which in turn means we cannot rank them for their Fix & Flip potential.`,
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#c7ddef70eef54360b6558e0dca6d80ba",
			disabledLink: "https://bezit.co/resources/non-disclosure-states-us/"
        },
		{
            label: "💎 Hidden Gems",
			title: "Hidden Gems",
			description: "This is a bonus opportunity that can be combined to each strategy. It provides investors an edge to uncover properties that have an opportunity for a low ball offer based on seller motivation.",
			shortDescription: "Seller finance properties, ADU opportunities, and more.",
			link: "https://coffeeclozers.notion.site/Strategies-b0ebd49f4e5a423190452d3af2fded59#dbaae26e6722453e85fc7057c4173ce6"
        },
    ];

	const openStrategyTooltip = (item) => {
		window.open(item.link, "_blank");
        recordEvent("STRATEGY_TOOLTIP_LINK_CLICKED", { "title": item.title });
	};

	const closeStrategyTooltip = () => {
		recordEvent("Close Strategy Tooltip", {});
		setShowStrategyTooltip(false);
	};

	const changeSelectedPanel = async(index) => {
		if ( strategiesLoading === true ) {
			return;
		}

		setStrategy(index);
		if ( strategyRefs.length === 6 && strategyRefs[index].current !== null ) {
			const strategyElementLeftPosition = strategyRefs[index].current.offsetLeft;
			const strategyElementWidth = strategyRefs[index].current.offsetWidth + 16;
			if ( chosenStrategyRef.current !== null ) {
				const chosenStrategyLeftPosition = chosenStrategyRef.current.offsetLeft;
				const newLeftPosition = strategyElementLeftPosition - 8;
				chosenStrategyRef.current.style.animation = "0.5s ease-in-out normal strategy-animate";
				chosenStrategyRef.current.style.setProperty('--start', `${chosenStrategyLeftPosition}px`);
    			chosenStrategyRef.current.style.setProperty('--end', `${newLeftPosition}px`);

				chosenStrategyRef.current.style.left = `${newLeftPosition}px`;
				chosenStrategyRef.current.style.width = `${strategyElementWidth}px`;

				setTimeout(() => {
					chosenStrategyRef.current.style.removeProperty('animation');
				}, 550);
			}
		}

		const strategyAnimationsClone = [...strategyAnimations];
		setStrategyAnimations(strategyAnimationsClone);
		setStrategiesLoading(true);
		if ( strategyAnimationsClone[index] === false ) {
			strategyAnimationsClone[index] = true;
			setTimeout(() => {
				setStrategiesLoading(false);
			}, 2000);
		}
		else {
			setTimeout(() => {
				setStrategiesLoading(false);
			}, 800);
		}

		setTimeout(async() => {
			setChangeMade("sorting");
			setLoading(true);
			recordEvent("Change Strategy", {
				strategy: strategies[index].label
			});
			
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});

			const checkedProperties = getSearchQueryState === null ? allProperties : mapProperties;
			const newData = await sortValues(checkedProperties, sorting, index);
			const lowerBound = 0;
			const upperBound = 20;
			const propsSegmentedByPage = newData.slice(lowerBound, upperBound);
			const mapLowerBound = 0;
			const mapUpperBound = mapLowerBound + 40;
			const propsSegmentedByMapPage = newData.slice(mapLowerBound, mapUpperBound);
			setPage(1);
			const labels = [
				"page"
			];
			const values = [
				1
			];
			await saveSearchParams(labels, values, searchParams, setSearchParams);

			setProperties(propsSegmentedByPage);
			setMapProperties(propsSegmentedByMapPage);
			setLoading(false);
			setShowStrategyTooltip(true);
		}, 800);
    };

	const arrowClicked = (event) => {
		event.stopPropagation();
	};

	const clickTract = (val) => {
		setCardIndex(null);

		if ( cardIndex === null ) {
			recordEvent("Click Tract", {
				tract: val
			});
			setClickedTract(val);
			setMapSidePanelOpen(true);

			for (let index = 0; index < neighbourhoodData.length; index++) {
				const element = neighbourhoodData[index];
				if ( element.region.tractId === val ) {
					setChosenTract(element);
				}
			}
		}
	};

	const onChangeNeighbourhoodOption = (val) => {
        if ( val === "hidden" ) {
            setHideHeatmap(true);
			recordEvent("Change Neighbourhood Option", {
				"Neighbourhood Option": "Hidden"
			});
        }
        else {
            setHideHeatmap(false);
            setNeighbourhoodOption(val);
			recordEvent("Change Neighbourhood Option", {
				"Neighbourhood Option": neighbourhoodDataOptions[val].label
			});
        }
    };

	const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik',
		},
		selectedMenuItem: {
			backgroundColor: colour.blueBlue01,
			fontFamily: 'Rubik',
		},
		formControl: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center'
		}
	};

	return (
		<div className="new-property-list-outer-container">
			{
				dataUploading === true ?
				<DataUploadingScreen />
				:
				null
			}
			{
				(innerWidth < 1000 && loading === true) ?
				<TextLoading
					stage={loadingStage}
					stages={loadingStages}
				/>
				:
				null
			}
			{
				filtersModalOpen === true || offMarketModal === true || emergingAreaModal ?
				<div className="new-property-list-dark-bg"></div>
				:
				null
			}
			<Header
				temporaryId={temporaryId}
				propertyListings={preFilteredProperties}
				listingsCityId={cityId}
				subscriptions={userSubscriptions}
                users={userDetails}
                queries={[false, false]}
				mobileNav={!filtersModalOpen}
			/>
			<div className={"new-property-list-inner-container " + (isBrowser === true ? "new-property-list-inner-container-desktop" : "") + (isMobile === true ? " new-property-list-inner-container-mobile" : "")}>
				{
					freeTrialExpired === true ?
					<FreeTrialExpired
						cityNoAccess={cityNoAccess}
					/>
					:
					signUpModal === true ?
					<SignUpModal
						setSignUpModal={setSignUpModal}
					/>
					:
					null
				}
				{
					emergingAreaModal === true ?
					<EmergingAreaModal
						setEmergingAreaModal={setEmergingAreaModal}
						setMapSidePanelOpen={setMapSidePanelOpen}
						setChosenTract={setChosenTract}
						setEmergingAreaTract={setEmergingAreaTract}
						emergingAreaTract={emergingAreaTract}
						setClickedTract={setClickedTract}
					/>
					:
					null
				}
				{
					freeTrialExpired === true || signUpModal === true ?
					<div className="free-trial-expired-blur">
					</div>
					:
					null
				}
				{
					offMarketModal === true ?
					<OffMarketModal
						setOffMarketModal={setOffMarketModal}
						userDetails={userDetails}
					/>
					:
					null
				}
				{
					userDetails !== null || temporaryId === true ?
					<PropertyListTopBar
						userDetails={temporaryId === true ? defaultUserData : userDetails}
						cityId={cityId}
						freeTrial={freeTrial}
						downloadCSV={downloadExcelFile}
						downloadCSVFreeTrial={downloadExcelFileFreeTrial}
						formatter={formatterCompact}
						setFiltersModalOpen={setFiltersModalOpen}
						selectedMapType={selectedMapType}
						setSelectedMapType={setSelectedMapType}
						liveSite={liveSite}
						mapViewOpen={mapViewOpen}
						setMapViewOpen={setMapViewOpen}
						setCardIndex={setCardIndex}
						setOverlay={setOverlay}
						temporaryId={temporaryId}
						setSignUpModal={setSignUpModal}
						cardsRef={cardsRef}
						offMarket={offMarket}
						setOffMarket={setOffMarket}
						loading={loading}
						setLoading={setLoading}
						setOnOffMarketChange={setOnOffMarketChange}
						setOffMarketModal={setOffMarketModal}
						setSorting={setSorting}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						sorting={sorting}
						setChangeMade={setChangeMade}
						setPage={setPage}
						setAddressSearchedProperty={setAddressSearchedProperty}
						setGoogleMapProperty={setGoogleMapProperty}
						changeMapType={changeMapType}
						propertyTypes={propertyTypes}
						setPropertyTypes={setPropertyTypes}
						units={units}
						setUnits={setUnits}
						budgetRange={budgetRange}
						setBudgetRange={setBudgetRange}
						bedrooms={bedrooms}
						setBedrooms={setBedrooms}
						bathrooms={bathrooms}
						setBathrooms={setBathrooms}
						squareFootage={squareFootage}
						setSquareFootage={setSquareFootage}
						constructionYears={constructionYears}
						setConstructionYears={setConstructionYears}
						onlyADU={onlyADU}
						setOnlyADU={setOnlyADU}
						onlyExtraBedroom={onlyExtraBedroom}
						setOnlyExtraBedroom={setOnlyExtraBedroom}
						priceReduction={priceReduction}
						setPriceReduction={setPriceReduction}
						priceReductionIndex={priceReductionIndex}
						setPriceReductionIndex={setPriceReductionIndex}
						onlySellerFinancing={onlySellerFinancing}
						setOnlySellerFinancing={setOnlySellerFinancing}
						hideFixerUppers={hideFixerUppers}
						setHideFixerUppers={setHideFixerUppers}
						onlyFixerUppers={onlyFixerUppers}
						setOnlyFixerUppers={setOnlyFixerUppers}
						setPropertiesOnPageChange={setPropertiesOnPageChange}
						buyBoxes={buyBoxes}
						cityStateName={cityStateName}
						strategy={strategy}
						subscriptions={userSubscriptions}
						neighbourhoodOption={neighbourhoodOption}
						setNeighbourhoodOption={setNeighbourhoodOption}
						fixerUpperOption={fixerUpperOption}
						setFixerUpperOption={setFixerUpperOption}
						fsbo={fsbo}
						setFsbo={setFsbo}
						neighbourhoodGradeMin={neighbourhoodGradeMin}
						neighbourhoodGradeMax={neighbourhoodGradeMax}
						setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
						setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
						hideHeatmap={hideHeatmap}
						onChangeNeighbourhoodOption={onChangeNeighbourhoodOption}
					/>
					:
					null
				}
				{
					filtersModalOpen === true ?
						<NewFiltersModal
							userDetails={temporaryId === true ? defaultUserData : userDetails}
							setFiltersModalOpen={setFiltersModalOpen}
							formatter={formatterCompact}
							zipCodeList={zipCodeList}
							cityId={cityId}
							setZipCodeList={setZipCodeList}
							searchParams={searchParams}
							sorting={sorting}
							offMarket={offMarket}
							setLoading={setLoading}
							setSearchParams={setSearchParams}
							downPaymentParam={getDownPayment}
							changeMapType={changeMapType}
							selectedMapType={selectedMapType}
							propertyTypes={propertyTypes}
							setPropertyTypes={setPropertyTypes}
							units={units}
							setUnits={setUnits}
							budgetRange={budgetRange}
							setBudgetRange={setBudgetRange}
							bedrooms={bedrooms}
							setBedrooms={setBedrooms}
							bathrooms={bathrooms}
							setBathrooms={setBathrooms}
							squareFootage={squareFootage}
							setSquareFootage={setSquareFootage}
							constructionYears={constructionYears}
							setConstructionYears={setConstructionYears}
							onlyADU={onlyADU}
							setOnlyADU={setOnlyADU}
							onlyExtraBedroom={onlyExtraBedroom}
							setOnlyExtraBedroom={setOnlyExtraBedroom}
							priceReduction={priceReduction}
							setPriceReduction={setPriceReduction}
							priceReductionIndex={priceReductionIndex}
							setPriceReductionIndex={setPriceReductionIndex}
							onlySellerFinancing={onlySellerFinancing}
							setOnlySellerFinancing={setOnlySellerFinancing}
							hideFixerUppers={hideFixerUppers}
							setHideFixerUppers={setHideFixerUppers}
							onlyFixerUppers={onlyFixerUppers}
							setOnlyFixerUppers={setOnlyFixerUppers}
							setChangeMade={setChangeMade}
							setPage={setPage}
							setPropertiesOnPageChange={setPropertiesOnPageChange}
							fixerUpperOption={fixerUpperOption}
							setFixerUpperOption={setFixerUpperOption}
							fsbo={fsbo}
							setFsbo={setFsbo}
							neighbourhoodGradeMin={neighbourhoodGradeMin}
							neighbourhoodGradeMax={neighbourhoodGradeMax}
							setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
							setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
						/>
					:
					null
				}
				<div className={"new-property-list-inner-row " + (offMarket === true ? "new-property-list-off-market-active" : "")}>
					<PropertyListButton
						clickFunc={() => openOverlay()}
						overlay={overlay}
					/>
					{
						mobileAbstract !== "" ?
						<div className="new-map-overlay-property-card-container new-map-overlay-property-card-container-mobile">
							{
								mobileAbstract.offMarket === false ?
								<MobilePropertyCardOverlay
									item={mobileAbstract}
									index={0}
									key={0}
									userDetails={userDetails}
									formatter={formatterLong}
									abstract={true}
									viewedProperties={viewedProperties}
									setViewedProperties={setViewedProperties}
									setMobileAbtract={setMobileAbtract}
									cityId={cityId}
									overlay={overlay}
									setOverlay={setOverlay}
								/>
								:
								<MobileOffMarketCardOverlay
									item={mobileAbstract}
									index={0}
									key={0}
									userDetails={userDetails}
									formatter={formatterLong}
									abstract={true}
									viewedProperties={viewedProperties}
									setViewedProperties={setViewedProperties}
									setMobileAbtract={setMobileAbtract}
									cityId={cityId}
									overlay={overlay}
									setOverlay={setOverlay}
								/>
							}
						</div>
						:
						null
					}
					{
						loading === true && firstLoading === false && (innerWidth >= 1000 && (( properties.length === 0 && allProperties.length !== 0 ) || noProperties === true)) ?
						<LoadingStatic />
						:
						(innerWidth >= 1000 && oversizedQuery === true) ?
						<OversizedQuery 
							cityStateName={cityStateName}
						/>
						:
						(innerWidth >= 1000 && (( properties.length === 0 && allProperties.length !== 0 ) || noProperties === true)) ?
						<NoAvailableProperties
							resetFilters={resetFilters}
							disabled={disabled}
							userDetails={userDetails}
							setDisabled={setDisabled}
							cityId={cityId}
							sorting={sorting}
							offMarket={offMarket}
							setLoading={setLoading}
						/>
						:
						activeMarket === false && creativeSubscription === false ?
						<FSBOOnly
						
						/>
						:
						<div 
							ref={cardsRef} className={"new-property-list-left-container new-property-list-left-container-strategies " + (overlay === false ? "new-property-list-hide-overlay" : "new-property-list-overlay-animate-in" )}
							onClick={(e) => clickOutside(e)}
						>
							{
								activeMarket === false && creativeSubscription === false ?
								null
								:
								totalProperties !== 0 ?
									<div className={"new-property-list-strategies-outer-row bg-colour-medium " + (strategiesAvailable === false || sorting > 1 ? " view-none" : "")}>
										<div className="new-property-list-strategies-row">
											<div 
												className={"new-property-list-strategy-selected-background " + (getStrategyParam === null && strategy === 0 ? "new-property-list-default-strategy-0" : getStrategyParam === null ? `new-property-list-default-strategy-${strategy}` : `new-property-list-default-strategy-${Number(JSON.parse(getStrategyParam))}`)}
												ref={chosenStrategyRef}
											>
												<span className="label-regular-caps colour-white">
													STRATEGY
												</span>
											</div>
											{
												strategies.map((item, index) =>
												<div 
													className="new-property-list-strategy-selector"
													onClick={() => changeSelectedPanel(index)}
													key={index}
													ref={strategyRefs[index]}
												>
													<span className={(strategy === index ? "body-semibold colour-white cursor-pointer" : "body-semibold colour-link")}>
														{item.label}
													</span>
												</div>
												)
											}
										</div>
										<div className="scroll-shadow new-property-list-strategies-scroll-shadow">
										</div>
										{
											showStrategyTooltip === true && strategiesAvailable === true && sorting < 2 ?
											<div className="strategies-tooltip-outer-container bg-colour-white margin-x-small">
												<div className="strategies-tooltip-inner-container">
													<div className="strategies-tooltip-inner-row-container">
														<span className="label-semibold colour-primary block-text subtext-margin-right">
															{strategies[strategy].title}
														</span>
														<span className="label-regular colour-secondary block-text subtext-margin-right">
															{strategies[strategy].shortDescription}
														</span>
														<div 
															className="button-row"
															onClick={() => openStrategyTooltip(strategies[strategy])}
														>
															<span className="label-regular colour-link underline block-text subtext-margin-right">
																Learn more
															</span>
															<svg 
																xmlns="http://www.w3.org/2000/svg" 
																width="8" 
																height="8" 
																viewBox="0 0 8 8" 
																fill="none"
															>
																<path 
																	d="M8 0.72392V7.23918C8 7.63899 7.67589 7.9631 7.27608 7.9631C6.87627 7.9631 6.55216 7.63899 6.55216 7.23919L6.55216 2.47161L1.23581 7.78797C0.953098 8.07068 0.494739 8.07068 0.212031 7.78797C-0.0706771 7.50526 -0.0706768 7.0469 0.212031 6.76419L5.52839 1.44784L0.760814 1.44784C0.361005 1.44784 0.0368958 1.12373 0.0368958 0.723919C0.0368957 0.324109 0.361005 0 0.760815 0L7.27608 1.07131e-06C7.67589 1.07131e-06 8 0.324111 8 0.72392Z" 
																	fill="#2F4858"
																/>
															</svg>
														</div>
													</div>
													<div 
														className="strategies-tooltip-exit-container cursor-pointer"
														onClick={() => closeStrategyTooltip()}
													>
														<svg 
															xmlns="http://www.w3.org/2000/svg" 
															width="8" 
															height="8" 
															viewBox="0 0 8 8" 
															fill="none"
														>
															<path 
																fillRule="evenodd" 
																clipRule="evenodd" 
																d="M1.30977 0.184663C1.10352 -0.0443967 0.750637 -0.0628907 0.521577 0.143356C0.292517 0.349603 0.274023 0.702488 0.48027 0.931548L3.24917 4.00673L0.492389 7.06845C0.286142 7.29751 0.304636 7.65039 0.533696 7.85664C0.762756 8.06289 1.11564 8.04439 1.32189 7.81533L4.00017 4.8408L6.67845 7.81533C6.8847 8.04439 7.23759 8.06288 7.46665 7.85664C7.69571 7.65039 7.7142 7.2975 7.50795 7.06844L4.75117 4.00673L7.52007 0.931551C7.72632 0.702491 7.70783 0.349605 7.47877 0.143358C7.24971 -0.0628883 6.89682 -0.0443943 6.69057 0.184666L4.00017 3.17266L1.30977 0.184663Z" 
																fill="#2F4858"
															/>
														</svg>
													</div>
												</div>
											</div>
											:
											null
										}
									</div>
								:
								null
							}
							{
								activeMarket === false && creativeSubscription === false ?
								null
								:
								totalProperties !== 0 ?
								<div className={"new-property-list-total-listings-container margin-medium " + (sorting > 1 || strategiesAvailable === false ? "no-strategies-padding" : sorting < 2 && strategiesAvailable === true && showStrategyTooltip === true ? "strategies-tooltip-open-padding" : "")}>
									<span className="body-regular colour-secondary">
										Showing {
											showResetButton === true ?
											numberFormatter.format(mapProperties.length)
											:
											numberFormatter.format(allProperties.length)
										} of {numberFormatter.format(totalProperties)} listings
									</span>
									<div className="new-property-list-sorting-container text-button">
										<Box>
											<FormControl sx={styles.formControl} fullWidth>
												<img
													src={Sort}
													className="pick-city-sort-icon"
													alt=""
												/>
												<Select
													labelId="sort-select-label"
													id="sort-select"
													value={sorting}
													label=""
													placeholder=""
													onChange={(event) => changeSorting(event.target.value)}
													input={<SortSelect />}
												>
													{
														sortOptions.map((item, index) =>
															<MenuItem 
																key={index}
																value={index}
																style={index === sorting ? styles.selectedMenuItem : styles.menuItem}
																disabled={index === 0 ? true : (index === 2 || index === 3) && offMarket === true ? true : false}
															>
																{item.label}
															</MenuItem>	
														)
													}
												</Select>
											</FormControl>
										</Box>
									</div>
								</div>
								:
								null
							}
							{
								overlay === true ?
								activeMarket === false && creativeSubscription === false ?
								null
								:
								<div 
									className={"new-property-list-mobile-overlay-top-bar " + (strategiesAvailable === false || sorting > 1 ? "new-property-list-mobile-overlay-small-padding" : strategiesAvailable === true && sorting < 2 && showStrategyTooltip === true ? "new-property-list-mobile-overlay-top-bar-strategies-tooltip" : "")}
								>
									<div className="new-property-list-mobile-total-listings-container">
										<span className="label-regular colour-secondary">
											Showing {
												showResetButton === true ?
												numberFormatter.format(mapProperties.length)
												:
												numberFormatter.format(allProperties.length)
											} of {numberFormatter.format(totalProperties)} listings
										</span>
									</div>
									<Box>
										<FormControl sx={styles.formControl} fullWidth>
											<img
												src={Sort}
												className="pick-city-sort-icon"
												alt=""
											/>
											<Select
												labelId="sort-select-label"
												id="sort-select"
												value={sorting}
												label=""
												placeholder="Interest Points"
												onChange={(event) => changeSorting(event.target.value)}
												input={<SortSelect />}
											>
												{
													sortOptions.map((item, index) =>
														<MenuItem 
															key={index}
															value={index}
															style={index === sorting ? styles.selectedMenuItem : styles.menuItem}
															disabled={index === 0 ? true : false}
														>
															{item.label}
														</MenuItem>	
													)
												}
											</Select>
										</FormControl>
									</Box>
								</div>
								:
								<div 
									className="new-property-list-mobile-overlay-title-container"
									onClick={() => setOverlay(!overlay)}
								>
									<h2 className="new-property-list-mobile-overlay-title-text">
										All Properties
									</h2>
									<img
										src={ChevronUp}
										className={"new-property-list-mobile-overlay-chevron " + (overlay === true ? "new-property-list-mobile-overlay-chevron-reverse" : "")}
										alt="Toggle Overlay"
									/>
								</div>
							}
							<div className={"new-property-list-cards-container " + (strategiesLoading === true ? "new-property-list-cards-strategies-loading-container" : "")}>
								{
									strategiesLoading === true ?
									<StrategyLoading
										index={strategy}
									/>
									:
									loading === true && firstLoading === false ?
									<LoadingStatic />
									:
									properties.length === 0 && innerWidth < 1000 ?
									<NoAvailableProperties
										resetFilters={resetFilters}
										disabled={disabled}
										userDetails={userDetails}
										setDisabled={setDisabled}
										cityId={cityId}
										sorting={sorting}
										offMarket={offMarket}
										setLoading={setLoading}
									/>
									:
									activeMarket === false && creativeSubscription === false ?
									<FSBOOnly
									
									/>
									:
									addressSearchedProperty !== null ?
									<NewPropertyCard
										item={addressSearchedProperty}
										index={0}
										userDetails={userDetails}
										formatter={formatterLong}
										abstract={false}
										viewedProperties={viewedProperties}
										setViewedProperties={setViewedProperties}
										cityId={cityId}
										setHoverCard={setHoverCard}
										propertyRefs={propertyRefs}
										onboarding={onboarding}
										onboardingStep={onboardingStep}
										setOnboardingStep={setOnboardingStep}
										setCardClick={setCardClick}
										temporaryId={temporaryId}
										setSignUpModal={setSignUpModal}
										overlay={overlay}
										setOverlay={setOverlay}
										propertySearch={false}
										partnerView={false}
										partnerEditProperty={null}
                                		partnerSoldProperty={null}
										partnerData={null}
										changeStatus={null}
										setShowExitScreen={null}
										selectOption={null}
										openStatus={null}
										chooseStatus={null}
										state={null}
										favourites={favourites}
										setFavourites={setFavourites}
										strategy={strategy}
										arrowClicked={arrowClicked}
										imageLimit={10}
									/>
									:
									properties.map((item, index) =>
										item.offMarket === false ?
										<NewPropertyCard
											item={item}
											index={index}
											key={index}
											userDetails={userDetails}
											formatter={formatterLong}
											abstract={false}
											viewedProperties={viewedProperties}
											setViewedProperties={setViewedProperties}
											cityId={cityId}
											setHoverCard={setHoverCard}
											propertyRefs={propertyRefs}
											onboarding={onboarding}
											onboardingStep={onboardingStep}
											setOnboardingStep={setOnboardingStep}
											setCardClick={setCardClick}
											temporaryId={temporaryId}
											setSignUpModal={setSignUpModal}
											overlay={overlay}
											setOverlay={setOverlay}
											propertySearch={false}
											partnerView={false}
											partnerEditProperty={null}
                                			partnerSoldProperty={null}
											partnerData={null}
											changeStatus={null}
											setShowExitScreen={null}
											selectOption={null}
											openStatus={null}
											chooseStatus={null}
											state={null}
											favourites={favourites}
											setFavourites={setFavourites}
											strategy={strategy}
											arrowClicked={arrowClicked}
											imageLimit={5}
										/>
										:
										<OffMarketCard
											item={item}
											index={index}
											key={index}
											userDetails={userDetails}
											formatter={formatterLong}
											abstract={false}
											viewedProperties={viewedProperties}
											setViewedProperties={setViewedProperties}
											cityId={cityId}
											setHoverCard={setHoverCard}
											propertyRefs={propertyRefs}
											onboarding={onboarding}
											onboardingStep={onboardingStep}
											setOnboardingStep={setOnboardingStep}
											setCardClick={setCardClick}
											temporaryId={temporaryId}
											setSignUpModal={setSignUpModal}
											overlay={overlay}
											setOverlay={setOverlay}
											innerWidth={innerWidth}
										/>
									)
								}
							</div>
							{
								addressSearchedProperty !== null ?
								null
								:
								activeMarket === false && creativeSubscription === false ?
								null
								:
								properties.length !== 0 ?
								<Pagination
									setMapProperties={setMapProperties}
									page={page}
									queryLength={showResetButton === false ? allProperties.length : mapProperties.length}
									propertiesOnPage={propertiesOnPage}
									propertiesOnPageChange={propertiesOnPageChange}
									setPropertiesOnPageChange={setPropertiesOnPageChange}
									cityId={cityId}
									setProperties={setProperties}
									setPage={setPage}
									cardsRef={cardsRef}
									searchParams={searchParams}
									setSearchParams={setSearchParams}
									allProperties={getSearchQueryState === null ? allProperties : mapProperties}
									temporaryId={temporaryId}
									setSignUpModal={setSignUpModal}
									setLoading={setLoading}
									offMarket={offMarket}
									setChangeMade={setChangeMade}
									everyProperty={allProperties}
								/>
								:
								null
							}
						</div>
					}
					<div className={"new-property-list-map-container " + (firstLoading === true ? "relative-container" : "")}>
						{
							
							firstLoading === true && noProperties === false ?
							<TextLoading
								stage={loadingStage}
								stages={loadingStages}
							/>
							:
							allProperties.length !== 0 || noProperties === true ?
							<NewPropertyMap
								properties={activeMarket === false && creativeSubscription === false ? [] : noProperties === true ? preFilteredProperties : mapProperties}
								userDetails={userDetails}
								setUserDetails={setUserDetails}
								formatterLong={formatterLong}
								centreCoordinates={centreCoordinates}
								viewedProperties={viewedProperties}
								setViewedProperties={setViewedProperties}
								allProperties={allProperties}
								setMobileAbtract={setMobileAbtract}
								innerWidth={innerWidth}
								cityId={cityId}
								hoverCard={hoverCard}
								propertyRefs={propertyRefs}
								setCardProperties={setProperties}
								setMapProperties={setMapProperties}
								noProperties={noProperties}
								setPropertiesOnPageChange={setPropertiesOnPageChange}
								setPage={setPage}
								setLoading={setLoading}
								setSearchParams={setSearchParams}
								cardIndex={cardIndex}
								setCardIndex={setCardIndex}
								cardClick={cardClick}
								setCardClick={setCardClick}
								onboarding={onboarding}
								sorting={sorting}
								searchParams={searchParams}
								showResetButton={showResetButton}
								setShowResetButton={setShowResetButton}
								selectedTracts={selectedTracts}
								setSelectedTracts={setSelectedTracts}
								mapSidePanelOpen={mapSidePanelOpen}
								lastClickedZIPCode={lastClickedZIPCode}
								setLastClickedZIPCode={setLastClickedZIPCode}
								lastClickedTract={lastClickedTract}
								setLastClickedTract={setLastClickedTract}
								setMapSidePanelOpen={setMapSidePanelOpen}
								sidePanelToggle={sidePanelToggle}
								temporaryId={temporaryId}
								setSignUpModal={setSignUpModal}
								overlay={overlay}
								setOverlay={setOverlay}
								setSidePanelCloseClicked={setSidePanelCloseClicked}
								loading={loading}
								setChangeMade={setChangeMade}
								setProperties={setProperties}
								mapProperties={mapProperties}
								setAllProperties={setAllProperties}
								addressSearchedProperty={addressSearchedProperty}
								setAddressSearchedProperty={setAddressSearchedProperty}
								getDownPayment={getDownPayment}
								getSearchQueryState={getSearchQueryState}
								cityName={cityName}
								googleMapProperty={googleMapProperty}
								setGoogleMapProperty={setGoogleMapProperty}
								favourites={favourites}
								setFavourites={setFavourites}
								strategy={strategy}
								neighbourhoodData={neighbourhoodData}
								neighbourhoodBounds={neighbourhoodBounds}
								tracts={tracts}
								neighbourhoodOption={neighbourhoodOption}
								clickTract={clickTract}
								chosenTract={chosenTract}
								clickedTract={clickedTract}
								hoverMapLegend={hoverMapLegend}
								setHoverMapLegend={setHoverMapLegend}
								bubbles={true}
								arrowClicked={arrowClicked}
								upgradeMetro={upgradeMetro}
								emergingMarkets={emergingMarkets}
								setEmergingAreaModal={setEmergingAreaModal}
								setEmergingAreaTract={setEmergingAreaTract}
								subscriptions={userSubscriptions}
								neighbourhoodGradeMin={neighbourhoodGradeMin}
								neighbourhoodGradeMax={neighbourhoodGradeMax}
								hideHeatmap={hideHeatmap}
							/>
							:
							null
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewPropertyList;